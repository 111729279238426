import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

// Create shortcuts for shorter easing names
const ElasticIn = TWEEN.Easing.Elastic.In
const ElasticOut = TWEEN.Easing.Elastic.Out
const QuadOut = TWEEN.Easing.Quadratic.Out;
const QuadIn = TWEEN.Easing.Quadratic.In;
const BounceIn = TWEEN.Easing.Bounce.In;
const BounceOut = TWEEN.Easing.Bounce.Out;

var shrinkScale = {x: 0.001, y: 0.001, z: 0.001};
var startScale = {x: 1, y: 1, z: 1};
var growScale = {x: 1.2, y: 1.2, z: 1.2};

export function Appear(mesh, ms){
	mesh.appear = true
    mesh.scale.copy(shrinkScale)
    mesh.visible = true
    new TWEEN.Tween(mesh.scale).to(startScale, ms, BounceOut).start()
}

export function Vanish(mesh, ms){
	mesh.appear = false
    new TWEEN.Tween(mesh.scale)
        .to(shrinkScale, ms, BounceIn)
        .start()
        .onComplete(()=>{mesh.visible=false})
}

export function GrowShrink(mesh, ms){
    new TWEEN.Tween(mesh.scale)
        .to(growScale, ms, QuadOut)
        .repeat( 1 )
        .yoyo( true )
        .start()
}

export function fillColor(mesh, progress, color){
    const options = {
        fromColor: null,
        toColor: color,
        progress: progress,
    }
    changeColor(mesh, options)
}

export function changeColor( mesh, options ) {

    const geom = mesh.geometry
    geom.computeBoundingBox();

    var bbox = geom.boundingBox;
    var size = new THREE.Vector3().subVectors(bbox.max, bbox.min); // we'll use it to get normalized positions of vertices in faces of geometry

    var vertex = new THREE.Vector3(), normalized = new THREE.Vector3(), normalizedY = 0, progressY = 0;

    var positions = mesh.geometry.attributes.position.array
    var colors = mesh.geometry.attributes.color.array;
    var segments = mesh.geometry.attributes.color.count * 3;
    var color = new THREE.Color();

    for ( var i = 0, l = segments; i < l; i ++ ) {
        vertex.set(positions[i*3], positions[i*3+1], positions[i*3+2])
        normalizedY = normalized.subVectors(vertex, bbox.min).divide(size).y; // we'll use the normalized Y-coordinate
        progressY = Math.min(Math.max(0, normalizedY + options.progress), 1)
        //color.set(options.colorFrom.clone().lerp(options.colorTo, progressY))
        color.set(options.colorTo)

        if(normalizedY <= options.progress){
            colors[ i * 4 ] = color.r;
            colors[ i * 4 + 1 ] = color.g;
            colors[ i * 4 + 2 ] = color.b;
            colors[ i * 4 + 3 ] = 1 //transparency
        } else {
            colors[ i * 4 ] = mesh.vertexColorArray[i * 4];
            colors[ i * 4 + 1 ] = mesh.vertexColorArray[i * 4 + 1];
            colors[ i * 4 + 2 ] = mesh.vertexColorArray[i * 4 + 2];
            colors[ i * 4 + 3 ] = mesh.vertexColorArray[i * 4 + 3];
        }
        
    }

  // update
    mesh.geometry.attributes[ "color" ].needsUpdate = true;

}


export function AnimationUpdate(){
	TWEEN.update()
}