import React from 'react'
import './styles/custom.scss'; //import last
import './App.scss'
import Emoji from './components/emoji'
import OfficeScene, {setActivePersons, setOnLoad} from './OfficeScene2d'
import ThreeSceneRenderer from '@bit/benolayinka.benolayinka.three-scene-renderer'
import {CSSTransition} from 'react-transition-group'
import Div100vh from 'react-div-100vh'
import CounterInput from "react-counter-input";
import ReactHowler from 'react-howler'
import { FaPlay, FaPause } from 'react-icons/fa';
import { Machine } from 'xstate'
import {Square, SemiCircle, Circle} from './components/kids-shapes'
import {s3} from './js/s3.js'
import {Modal} from 'react-bootstrap'
var WebFont = require('webfontloader');

WebFont.load({
	custom: {
		families: ['Apercu, ClearfaceITCPro'],
		urls: [s3 + "/fonts/fonts.css"],
	}
});

const initialVolume = 5
const maxCount = 10
const minCount = 0

// Authentication state machine 
const playState = Machine({
  initial: 'stopped',
  states: {
	stopped: {
	  onEntry: ['error'],
	  on: {
		PLAY: 'loading',
	  },
	},
	loading: {
	  on: {
		SUCCESS: 'playing',
		FAIL: 'stopped',
		STOP: 'stopped',
	  },
	},
	playing: {
	  onEntry: ['setPlaying'],
	  onExit: ['setStopped'],
	  on: {
		STOP: 'stopped',
	  },
	},
  },
});

const KidsLink = () => (
	<a 
		className='link'
		href="https://www.thekids.eu?ref=imisstheoffice" 
		target="_blank" 
		rel="noopener noreferrer"
	>
		Kids Creative Agency
	</a>
)

const MailLink = () => (
	<a 
		className='link'
		href="mailto:hello@thekids.eu?subject=I Miss the Office"
	>
		Get in contact <Emoji symbol="💌" label="mail"/>
	</a>  
)

const IGLink = () => (
	<a 
		className='link'
		href="https://www.instagram.com/notyourkids/"
		target="_blank" 
		rel="noopener noreferrer"
	>
		IG <Emoji symbol="📸" label="instagram"/>
	</a>  
)

const sounds = {
	pfranzen: 'https://freesound.org/people/pfranzen/sounds/380170/', 
	suhral: 'https://freesound.org/people/suhral/sounds/347299/', 
	mwmarsh: 'https://freesound.org/people/mwmarsh/sounds/85944/', 
	coral_island_studios: 'https://freesound.org/people/Coral_Island_Studios/sounds/277597/', 
	inspectorj: 'https://freesound.org/people/InspectorJ/sounds/336598/', 
	tianve8: 'https://freesound.org/people/Tianve8/sounds/366874/',
}

const len = Object.keys(sounds).length;

const SoundLinks = Object.keys(sounds).map((name, i) => 
		<span key={name}>
		    <a 
		    	className='link' 
				target="_blank" 
				rel="noopener noreferrer"
				href={sounds[name]}
			>
				{name}
			</a>
			{i !== len-1 && ', '}
		</span>
	)

export default class App extends React.Component {
	constructor(props) {
		super(props)

		this.onSceneLoad = this.onSceneLoad.bind(this)
		setOnLoad(this.onSceneLoad)

		this.onPlayButton = this.onPlayButton.bind(this)
		this.onPlaying = this.onPlaying.bind(this)
		this.onCountChange = this.onCountChange.bind(this)
		this.onLoadingTextExit = this.onLoadingTextExit.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.showModal = this.showModal.bind(this)

		this.state= {
			ambienceUrl: s3 + '/audio/ambience/1.mp3',
			playState: playState.initialState.value,
			sceneLoaded:false,
			showLoadingText:true,
			showLoadingScreen:true,
			playing: false,
			volume: initialVolume*0.1,
			showModal: false,
		}
	}

	transition(event) {
		const nextPlayState = playState.transition(this.state.playState, event);
		this.setState({
			playState: nextPlayState.value,
		});
	}

	hideModal(){
		this.setState({showModal:false})
	}

	showModal(){
		this.setState({showModal:true})
	}

	onCountChange(count){
		this.setState({volume: count * .1})
		setActivePersons(count / maxCount)
	}

	onPlayButton(event){
		if(this.state.playState !== 'stopped')
			this.transition('STOP')
		else{
			this.transition('PLAY')
		}
	}

	onPlaying(){
		this.transition('SUCCESS')
	}

	onSceneLoad(){
		setActivePersons(this.state.volume)

		this.setState({
			sceneLoaded: true,
		})

		setTimeout(()=>{this.setState({showLoadingText:false})}, 2000)
	}

	onLoadingTextExit(){
		this.setState({showLoadingScreen: false,})
	}

	render(){
		return(
			<div className = 'App'>
				<Div100vh>
					<CSSTransition
							in={this.state.showLoadingScreen}
							unmountOnExit
							timeout={1200}
							classNames="fade"
					>
						<div className='h-100 w-100 position-absolute z-9 bg-tan d-flex flex-column justify-content-center align-items-center'>
							<CSSTransition
									in={this.state.showLoadingText}
									unmountOnExit
									timeout={1200}
									classNames="fade"
									onExited={this.onLoadingTextExit}
							>
								<div className = 'd-flex justify-content-center'>
									<img className='img-size bounce p-4' src={Circle} alt="loading" />
									<img className='img-size bounce p-4' src={SemiCircle} alt="loading" />
									<img className='img-size bounce p-4' src={Square} alt="loading" />
								</div>
							</CSSTransition>
						</div>
					</CSSTransition>
					<ThreeSceneRenderer className='h-100 w-100 position-absolute' adaptToDeviceRatio gammaCorrect onMount={OfficeScene}/>
					<ReactHowler
						src={this.state.ambienceUrl}
						playing={this.state.playState !== 'stopped'}
						onPlay={this.onPlaying}
						volume={this.state.volume}
						onEnd={this.onAmbienceEnd}
						loop={true}
						preload={false}
						html5={true}
					  />
					<Modal
						centered
						show={this.state.showModal}
						onHide={this.hideModal}
						aria-labelledby="office-sound-generator"
					>
						<Modal.Header className = 'bg-tan no-border'>
							<Modal.Title className = 'p-4'>
				          		<h1 className = 'clearface s-3'>
									I Miss the Office
								</h1>
				            	<div className = 's-1 py-1'>
									An office noise generator - providing you with the soothing tones of modern office life to help you focus when working from home.
								</div>
								<div className = 's-1 py-1'>
									Click all the things to turn them on <Emoji symbol="🔈" label="mail"/>
								</div>
								<div className = 's-1 py-1'>
									Design: Fred & Vali @ <KidsLink />
								</div>
								<div className = 's-1 py-1'>
									Dev: Ben @ <KidsLink />
								</div>
								<div className = 's-1 py-1'>
									Sounds: {SoundLinks}
								</div>
								<div className = 's-1 py-1'>
									<MailLink/>
								</div>
								<div className = 's-1 py-1'>
									<IGLink/>
								</div>
				          	</Modal.Title>
				          	<button type="button" className="close btn-naked" onClick={this.hideModal}>
				          		<span aria-hidden="true">×</span>
				          		<span className="sr-only">Close</span>
				          	</button>
				        </Modal.Header>
					</Modal>
					<div className = 'position-absolute p-4 pointer-events-none max-width t-0 l-0'>
						<div className = 'clearface s-3 pointer-events-none'>
							<div>Close your eyes and imagine you're in the office.</div>
							<div>Beautiful, right?</div>
						</div>
						<div className = 's-1 py-1'>
							Click all the things to turn them on <Emoji symbol="🔈" label="mail"/>
						</div>
						<div className = 's-1 py-1 pointer-events-all'>
							A #WFH project by&nbsp;
							<KidsLink />
						</div>
					</div>
					<button className = 's-3 btn-naked position-absolute p-4 t-0 r-0' onClick={this.showModal}>
						?
					</button>
					<div className = 'position-absolute p-4 max-width b-0 r-0'>
						<div className = 'justify-content-center'>
							<CounterInput
								className = 'justify-content-center'
								wrapperStyle = {{justifyContent: 'center'}}
								inputStyle = {{fontSize: '2rem', width: '3rem', height: 'auto'}}
								min={minCount}
								max={maxCount}
								hideDisabledButtons
								count={initialVolume}
								onCountChange={this.onCountChange}
							  />
						</div>
						<div>
							Number of colleagues
						</div>
					</div>
					<div className = 'position-absolute p-4 b-0 l-0'>
						<button 
							className='btn-naked s-4 p-0'
							disabled={this.state.playState === 'loading'} 
							onClick={this.onPlayButton}
						>
							{
							this.state.playState === 'playing' ?
							<FaPause />
							:
							<FaPlay />
							}
						</button>
					</div>
				</Div100vh>
			</div>
			)
	}
}