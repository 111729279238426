import { Interaction } from 'three.interaction';
import {GrowShrink} from './components/mesh-animations'
import {playSound} from './js/sounds.js'

const isTouch = 'ontouchstart' in window;

export default class ClickHandler{

	constructor(camera, renderer, scene){
		this.interaction = new Interaction(renderer, scene, camera)
	}

	click(mesh){

		if(!mesh) return
		if(!mesh.clickable) return

		mesh.onMouseOver()
		mesh.onClick()
		mesh.onMouseOut()
	}

	makeClickable(mesh, sound){

		if(!mesh) return

		mesh.currentHex = mesh.material.emissive.getHex();

		if(mesh.geometry.attributes.color)
			mesh.vertexColorArray = mesh.geometry.attributes.color.array.slice()

		mesh.clickable = true

		const onMouseOver = function(){
			this.material.emissive.setHex( 0xffffff );
		}

		const onMouseOut = function(){
			if(!this.sound){
				this.material.emissive.setHex( this.currentHex );
			}
		}

		const onClick = function(){
			GrowShrink(this, 50)

			if(sound){
				if(this.sound){
					this.sound.stop()
					this.sound = null
				}
				else{

					let onEnd = () => {
						this.sound = null
						this.onMouseOut()
					}

					this.sound = playSound(sound, onEnd)
				}
			}
		}

		mesh.onMouseOver = onMouseOver.bind(mesh)

		mesh.onMouseOut = onMouseOut.bind(mesh)

		mesh.onClick = onClick.bind(mesh)

		mesh.cursor = 'pointer'

		if(isTouch){
			mesh.on('touchstart', mesh.onClick)
			mesh.on('touchstart', ()=>{mesh.onMouseOver(); setTimeout(()=>{mesh.onMouseOut()},100)})
			//this.domEvents.addEventListener(mesh, 'touchstart', mesh.onClick, false)
			//this.domEvents.addEventListener(mesh, 'touchstart', ()=>{mesh.onMouseOver(); setTimeout(()=>{mesh.onMouseOut()},100)}, false)
			//this.domEvents.addEventListener(mesh, 'touchend', onMouseOut, false)
		} else{
			mesh.on('mouseover', mesh.onMouseOver)
			mesh.on('mouseout', mesh.onMouseOut)
			mesh.on('click', mesh.onClick)
			//this.domEvents.addEventListener(mesh, 'mouseover', mesh.onMouseOver, false)
			//this.domEvents.addEventListener(mesh, 'mouseout', mesh.onMouseOut, false)
			//this.domEvents.addEventListener(mesh, 'click', mesh.onClick, false)
		}
	}
}