import {Howl} from 'howler'
import {s3} from './s3.js'

const lens = {
	chair: 2,
	computer: 5,
	misc: 6,
	person: 10,
	printer: 4,
	water: 2,
	table: 1,
}

const sounds = {}

//create obj of arrays of sounds
for (const [sound, len] of Object.entries(lens)) {
  let t = []
  for(var i = 1; i <= len; i++){
  	let howl = new Howl({
        src: [s3 + '/audio/' + sound + '/' + i.toString() + '.mp3'],
        preload: false,
        html5: true,
    });
  	t.push(howl)
  }
  sounds[sound] = t
}

function handleLoad(callback){
	const node = this._sounds[0]._node;
	node.addEventListener('progress', () => {
		const duration = this.duration();
		if (duration > 0) {
		  for (let i = 0; i < node.buffered.length; i++) {
		    if (node.buffered.start(node.buffered.length - 1 - i) < node.currentTime) {
		      const bufferProgress = (node.buffered.end(node.buffered.length - 1 - i) / duration) * 100;
		      // do what you will with it. I.E - store.set({ bufferProgress });
		      callback(bufferProgress)
		      break;
		    }
		  }
		}
	})
}

export function playSound(sound, onEnd, onProgress){

	const i = Math.random() * sounds[sound].length | 0

	const howl = sounds[sound][i]

	//call callback if sound is playing already
	if(howl.playing()){
		if(typeof howl.onend === "function"){
		    howl.stop()
	    	howl.onend()
		}
	}

	//register onEnd callback if given
	if(typeof onEnd === "function"){
	    howl.onend = onEnd
	    howl.once('end', onEnd)
	}

	//register onProgress callback if given
	if(typeof onProgress === "function"){
	    howl.onprogress = onProgress
	    howl.once('end', onEnd)
	    const loader = handleLoad.bind(howl, onProgress)
    	howl.on('load', loader);
	}

	//play
	howl.play()

	return howl
}