import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import Colors from './styles/Colors2d.scss';
import {Appear, Vanish, AnimationUpdate} from './components/mesh-animations'
import {Cloud} from './components/meshes'
import Stats from 'three/examples/jsm/libs/stats.module.js'
import {getQuery} from './js/utils'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import ClickHandler from './ClickHandler';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import {s3} from './js/s3.js'
import {showEdges, setEdgeWidth, getRandomSphericalCoords} from '@bit/benolayinka.benolayinka.utils'

const NEAR = 0.1, FAR = 600, FOV = 40, ASPECT = 16/9

const SKY_RADIUS = 70
const SCENE_RADIUS = 5

const STARS = 100
const CLOUDS = 20

const EDGE_THICKNESS = 1

const controlParamsPortrait = {
	minDistance : 30,
    maxDistance : 100,
    enableDamping : true,
    rotateSpeed : 0.05,
    zoomSpeed : 0.1,
    panSpeed : 0.2,
    maxPolarAngle : Math.PI/3,
    minPolarAngle : Math.PI/4, //from top axis
    maxAzimuthAngle : -Math.PI/4,
    minAzimuthAngle : -Math.PI/3, //from 0
}

const controlParams = {
	minDistance : 30,
    maxDistance : 35,
    enableDamping : true,
    rotateSpeed : 0.05,
    zoomSpeed : 0.05,
    panSpeed : 0.1,
    maxPolarAngle : Math.PI/3,
    minPolarAngle : Math.PI/4, //from top axis
    maxAzimuthAngle : -Math.PI/4,
    minAzimuthAngle : -Math.PI/3, //from 0
}

var canvas, scene, renderer, mixer, camera, controls, clickHandler, randomClickInterval, onLoad
const persons = []
const nonpersons = [] //clickable objects that arent persons

export default function OfficeScene(props){

	canvas = props.canvas
	scene = props.scene || new THREE.Scene()
	renderer = props.renderer

	renderer.setClearColor(Colors.tan, 1)

	const clock = new THREE.Clock();

	//display stats if query parameter includes ?debug=true
    const query = getQuery();
    const debug = 'debug' in query

    var stats
    if(debug)
    {
    	createGrid()
    	createStats()
    	initGui()
    }

    createCamera()

    createControls()

    clickHandler = new ClickHandler(camera, renderer, scene)

    //reduce star density on small screens
    const numStars = canvas.clientWidth > 767 ? STARS : STARS / 2
    const numClouds = CLOUDS

	createStars(numStars, SKY_RADIUS)
	createClouds(numClouds, SKY_RADIUS)

	createLights()

	createLoading()

	loadOffice()

	window.addEventListener( 'resize', resizeCameraToDisplaySize );

	resizeCameraToDisplaySize()

	animate()

	function createStats(){
        stats = new Stats();
        stats.showPanel( 0 );
        document.body.appendChild( stats.domElement );
	}

	function createControls(){
		controls = new OrbitControls( camera, canvas );
	}

	function createCamera(){
		camera = new THREE.PerspectiveCamera(
		    FOV, //Field of view
		    ASPECT,
		    NEAR, //Near plane
		    FAR, //Far plane
		)
	}

	function loadOffice(){
		const loader = new GLTFLoader()
		loader.load( s3 + '/3d/room_full_2d_t.glb', ( gltf ) => {

			function showWireframe(mesh){
				const geometry = mesh.geometry
				var edges = new THREE.EdgesGeometry( geometry, 45 ); //threshold angle
				var line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 'black' } ) );
				mesh.add( line );
			}

			gltf.scene.traverse((child) => {
				if(child.type === "Mesh"){
					//showWireframe(child)
					showEdges(child)
				}
				if(child.userData.clickable){
					if(child.userData.sound)
						clickHandler.makeClickable(child, child.userData.sound)
					else
						clickHandler.makeClickable(child)
				}
				if(child.userData.person){
					persons.push(child)
					child.visible = false
				}
				if(child.userData.clickable && !child.userData.person){
					nonpersons.push(child)
				}
			});

			scene.userData.animations = []
			mixer = new THREE.AnimationMixer(gltf.scene);
			gltf.animations.forEach((clip) => {
				scene.userData.animations.push(clip)
				mixer.clipAction(clip).play();
			});

			scene.add(gltf.scene)
			camera.lookAt(gltf.scene)

		} );
	}

	function createLoading(){
		THREE.DefaultLoadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
	        //console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
	    };

	    THREE.DefaultLoadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
	        //console.log( 'Loaded file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
	    };

	    THREE.DefaultLoadingManager.onError = function ( url ) {
	        //console.log( 'There was an error loading ' + url );
	    };

	    //trigger onLoaded callback when all assets are loaded
	    THREE.DefaultLoadingManager.onLoad = () => {
	        //console.log( 'Done loading ');
	        if(typeof onLoad === 'function'){
	        	onLoad()
	        }
	    };
	}

	function createClouds(numClouds = 20, cloudRadius = 100){
		for ( var i = 0; i < numClouds; i ++ ) {
			const coords = getRandomSphericalCoords(cloudRadius)
			const cloud = new Cloud(1, true)
			cloud.mesh.position.set(coords.x,coords.y,coords.z)
			scene.add(cloud.mesh)
		}
	}

	function createStars(numStars = 100, starRadius = 100){
		var vertices = [];

		for ( var i = 0; i < numStars; i ++ ) {

			const coords = getRandomSphericalCoords(starRadius)
			vertices.push(coords.x,coords.y,coords.z);

		}

		var geometry = new THREE.BufferGeometry();
		geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

		var material = new THREE.PointsMaterial( { color: 'black' } );

		var points = new THREE.Points( geometry, material );

		scene.add( points );
	}

	function createLights(){
		//var hemisphereLight = new THREE.HemisphereLight('white', 'black')
		//this.$scene.add(hemisphereLight)

		var ambient = new THREE.AmbientLight( 'white', 1 );
        scene.add( ambient );

  //       var sunlight = new THREE.DirectionalLight(Colors.white, 0.5)
  //   	sunlight.position.set(SKY_RADIUS/4, SKY_RADIUS/4, SKY_RADIUS/4)
  //   	scene.add( sunlight );

  //   	const helper = new THREE.DirectionalLightHelper( sunlight, 1 );
  //   	helper.update()
  //   	scene.add( helper );

  //   	const pointlight = new THREE.PointLight( Colors.white, 0.75, 100 );
		// pointlight.position.set( 0, SKY_RADIUS/4, SKY_RADIUS/4 );
		// scene.add( pointlight );

		// var sphereSize = 1;
		// var pointlightHelper = new THREE.PointLightHelper( pointlight, sphereSize );
		// scene.add( pointlightHelper );
	}

	function createGrid(){
		var size = SCENE_RADIUS;
		var divisions = SCENE_RADIUS;

		var gridHelper = new THREE.GridHelper( size, divisions);
		scene.add( gridHelper );

		var axesHelper = new THREE.AxesHelper();
		scene.add( axesHelper );
	}

	function animate(){

		var delta = clock.getDelta();

		if(mixer){
			mixer.update(delta)
		}

		if(debug){
            stats.end()
            stats.begin()
        }

        if(controls){
        	autoRotate(controls)
            if(controls.enableDamping){
                controls.update();
            }
        }
        
        renderer.render(
            scene,
            camera
        );

        if(AnimationUpdate)
        	AnimationUpdate()

        //requesting at end means loop will stop on error
        requestAnimationFrame(animate)
	}

    function resizeCameraToDisplaySize(){
        // look up the size the canvas is being displayed
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;

        // adjust displayBuffer size to match
        if (canvas.width !== width || canvas.height !== height) {
            // you must pass false here or three.js sadly fights the browser
            camera.aspect = width / height;
    		camera.updateProjectionMatrix();
        }

        var portrait = width < height
		const params = portrait ? controlParamsPortrait : controlParams
		applyControlParams(controls, params)
    }

    function initGui() {

		const gui = new GUI();

		var param = {
			'width (px)': EDGE_THICKNESS,
		};

		gui.add( param, 'width (px)', 0.1, 10 ).onChange( function ( val ) {

			setEdgeWidth(val)

		} );
	}
}

export function setOnLoad(func){
	onLoad = func
}

export function setAnimationTimeScale(scale){
	scene.userData.animations.forEach((clip)=>{
		mixer.clipAction(clip).timeScale = scale
	})
}

function setRandomClickInterval(interval){
	if(randomClickInterval){
		clearInterval(randomClickInterval)
	}

	randomClickInterval = setInterval(clickRandomObject, interval)
}

export function clickRandomObject(){
	if(nonpersons.length === 0) return

	const i = (Math.random() * nonpersons.length) | 0

	if (typeof clickHandler.click === "function") {
	    clickHandler.click(nonpersons[i])
	}
}

function map( x,  in_min,  in_max,  out_min,  out_max){
  	return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

const fast = 30 * 1000 //30 seconds
const slow = 5 * 60 * 1000 //5 mins

export function setActivePersons(percent){
	setRandomClickInterval(map(percent, 0, 1, slow, fast))

	for(var i = 0; i < persons.length; i++){
		//show below percent
		if(i < persons.length * percent){
			if(!persons[i].appear)
				Appear(persons[i], 500)
		}
		else{
			if(persons[i].appear)
				Vanish(persons[i], 500)
		}
	}
}

function applyControlParams(controls, params){
	Object.assign(controls, params)
    const rad = (controls.maxDistance + controls.minDistance)/2
    const pol = (controls.maxPolarAngle + controls.minPolarAngle)/2
    const azi = (controls.maxAzimuthAngle + controls.minAzimuthAngle)/2
    controls.object.position.setFromSphericalCoords ( rad, azi, pol )
    controls.update();
	window.controls = controls
}

var dpol = .01
var dazi = .01

const speed = .01
var angle = 0

function autoRotate(controls){
	// const pol = controls.getPolarAngle()
	// const azi = controls.getAzimuthalAngle()

	// const {x,y,z} = controls.object.position
	// const radius = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));

	// //const dpol = pol >= controls.maxPolarAngle ? -.01 : .01
	// const dazi = azi >= controls.maxAzimuthAngle ? -.01 : .01

	// const epol = pol + dpol
	// const eazi = azi + dazi

 //    controls.object.position.setFromSphericalCoords ( radius, eazi, epol )
    angle += speed;
	controls.object.position.x = controls.object.position.x + (Math.sin(angle) * .01);
}