import * as THREE from 'three'
import {BufferGeometryUtils} from 'three/examples/jsm/utils/BufferGeometryUtils.js';

export default function Cloud(size, wireframe, color){
    //create a merged geometry to reduce the number of meshes drawn
    var geoms = []

	// duplicate the geometry a random number of times
	var nBlocs = 3+Math.floor(Math.random()*3);
	for (var i=0; i<nBlocs; i++ ){

        // create a cube geometry;
        // this shape will be duplicated to create the cloud
        var geom = new THREE.BoxBufferGeometry(size,size,size);
        geom.translate(Math.random()*10, Math.random()*10, Math.random()*10)

        //rotate
        geom.rotateX(Math.random()*Math.PI*2)
        geom.rotateY(Math.random()*Math.PI*2)

        //scale randomly
        var s = .1 + Math.random()*.9;
        geom.scale(s,s,s);

        geoms.push(geom)
	}

    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
      geoms, false);

    color = color || 0xffffff
    wireframe = wireframe || false

	var mat = new THREE.MeshBasicMaterial({
        wireframe:wireframe,
		color:color,
	});

    this.mesh = new THREE.Mesh(mergedGeometry, mat)

}